.paddingbutton {
  padding: 14px 24px !important;
}

.paddingLR3P {
  padding-left: 3% !important;
  padding-right: 3% !important;
}
.paddingBottom4 {
  padding-bottom: 4% !important;
}
.paddingBottom5 {
  padding-bottom: 5% !important;
}

.blogCardBox {
  border-radius: 30px !important;
}
.imageBorder {
  border-radius: 12px;
  /* object-fit: cover; */
}
.linebottom {
  border-bottom: 2px solid #000;
}
.imageblogHeight {
  min-height: 300px;
  height: 300px;
}
.borderBottomNav {
  border-bottom: 1px solid #e5e7eb;
}
.img300px {
  min-height: 300px !important;
  height: 300px !important;
}
.cardHeight175 {
  height: 175px;
}
.textOverFlow2Line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.textOverFlow22 {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.textOverFlow {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardHeadText {
  margin: 0;
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.12px;
}

.cardTextText {
  margin: 0;
  color: var(--neutral-10, #000);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08px;
}
.cardLink {
  text-decoration: none;
  margin: 0;
  color: var(--secondary-07, #0cc0df);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.08px;
}
.contactFirstBg {
  background: var(--secondary-01, #fff);
}
.contactFirstBg2 {
  background: var(--secondary-01, #e0f2fe);
}
.inputBoxPlaceHolder {
  color: var(--neutral-10, #000) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.inputBox {
  color: var(--neutral-10, #000) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  border: 1px solid var(--neutral-04, #d1d5db) !important;
  background: var(--white, #fff);
  padding: 12px !important;
}
.readMehover:hover {
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: var(--shades-white, #1f2937);
}
.imgs3 {
  height: 45px !important;
  width: 100% !important;
}
.logoBgcolor {
  height: 60px;
  border-radius: 10px;
  background: #0d0000;
}
.logoBgcolor2 {
  height: 60px;
  border-radius: 10px;
  background: #0f0f0f;
}
.linkHover:hover {
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: var(--shades-white, #1f2937);
}
.blogTextHead {
  margin: 0;
  color: var(--black, #000);
  text-align: center;
  font-size: 48px;
  font-family: Playfair Display;
  font-weight: 600;
  letter-spacing: 0.24px;
}
.faqbgInputColor {
  background: var(--secondary-01, #fff) !important;
  border-bottom: 1px solid #000 !important;
}
.logoText {
  margin-left: 4px;
  color: var(--secondary-07, #ea580c);
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.78px;
}
.navButtonText {
  color: var(--neutral-10, #111928);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.navProfilePicBox {
  border-radius: 999px;
  background: var(--neutral-04, #d1d5db);
  width: 44px;
  height: 44px;
}
.navProfilePic {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.blankLine {
  margin-left: 16px !important;
  margin-right: 16px !important;
  width: 1px;
  height: 32px;
  border-right: 1px solid #d1d5db;
}
.navHover:hover {
  border-radius: 10px;
  background: var(--secondary-07, #d1d5db);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  color: var(--shades-white, #1f2937);
}

.whiteButton {
  border-radius: 10px;
  border: 1px solid var(--neutral-04, #d1d5db);
  background: var(--shades-white, #fff);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
}
.whiteButton:hover {
  background: var(--secondary-07, #d1d5db);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  color: var(--shades-white, #1f2937);
}
.whiteText {
  color: var(--neutral-09, #1f2937);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
  text-decoration: none !important;
}
.orangeButton {
  border-radius: 10px;
  background: var(--secondary-07, #0cc0df);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
}
.orangeButton:hover {
  border-radius: 10px;
  background: var(--secondary-07, #0ea5e9);
  color: var(--shades-white, #fff);
}
.orangeButtonText {
  color: var(--shades-white, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.containerVideo {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  top: 25%;
}
.imageBorderBlack {
  padding: 15px;
  border-radius: 10px;
  background: #0d0000;
}
.card {
  border: none !important;
}
.borderNone {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px #eaf0f6;
}
.font18gray400 {
  color: var(--neutral-09, #1f2937);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;
}
.font20black600 {
  color: var(--neutral-09, #1f2937);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
}
.fontUserName {
  color: var(--neutral-07, #4b5563);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.08px;
}
.card:hover {
  border-radius: 16px;
  transform: scale(1.009);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.cardTitleName {
  color: var(--neutral-10, #000);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
}
.padding30 {
  padding: 30px;
}
.normalText14400 {
  margin: 0;
  color: var(--neutral-08, #374151);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.07px;
}
.noramlTextheight {
  max-height: 220px;
  min-height: 220px;
}
.cardgrayColorText {
  margin: 0;
  color: var(--neutral-07, #4b5563);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.07px;
}
.rightTextMenu {
  padding-left: 8px;
  margin: 0;
  color: var(--neutral-08, #374151);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.allplatformBGcolor {
  background-color: #e0f2fe;
  position: relative;
}
.allplateformtextBox {
  position: absolute;
  top: 0%;
  right: 0%;
}
.imageHeightAllplate {
  height: 50%;
}
.font60black700 {
  color: var(--black, #000);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.font16gray600 {
  color: var(--neutral-07, #4b5563);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font16gray400 {
  color: var(--black, #374151);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font18black600 {
  color: var(--black, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font48black700 {
  color: var(--black, #000);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}
.font16black600 {
  color: var(--black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font600 {
  font-weight: 600 !important;
}
.font16black400 {
  color: var(--black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font18black400 {
  color: var(--black, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;
}
.font14black600 {
  color: var(--black, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.font14black400 {
  color: var(--black, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.font14white400 {
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font16white600 {
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}
.font30black700 {
  color: var(--black, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.15px;
}
.font36black600 {
  color: var(--black, #000);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
}
.font36black700 {
  color: var(--black, #000);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.font56black700 {
  color: var(--black, #000);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 67.2px */
}
.font60white700 {
  color: var(--shades-white, #fff);
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.font18white400 {
  color: var(--neutral-02, #f3f4f6);
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.09px;
}
.blogpageWidth {
  width: 50% !important;
  height: 500px;
}
.imgsAbout {
  width: 100% !important;
  height: 100% !important;
}

.imgs {
  border-radius: 20px;
  width: 100% !important;
  height: 100% !important;
}
.parent {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* Magic */
  display: flex;
  align-items: center; /* vertical */
  justify-content: center; /* horizontal */
}
.imgsss {
  width: calc(100% - 200px) !important;
  height: calc(100% - 100px) !important;
}
.blogimageBoxDetail {
  /* height: auto; */
  width: 100%;
  min-height: 650px;
  height: 650px;
}
.imageBorder {
  border-radius: 12px;
  /* object-fit: cover; */
}
.blogimageBox {
  /* height: auto; */
  width: 100%;
  min-height: 300px;
  height: 250px;
}
.pading20 {
  padding-left: 20%;
  padding-right: 20%;
}
.contactEmailText {
  margin: 0px;
  color: var(--black, #000);
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.contactEmailTextHead {
  margin: 0px;
  color: var(--neutral-10, #000);
  text-align: center;
  font-size: 30px;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
}

.contactEmailId {
  margin: 0px;
  color: var(--black, #000);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  text-decoration-line: underline;
}
/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.flexmedia {
  display: flex;
}
.w-75media {
  width: 75% !important;
}
.px-5media {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.w-50meida {
  width: 50%;
}
/* +++++++++++++++++++++++++++++Media++++++++++++++++++++++++++= */

@media (max-width: 991.5px) {
  .textaline {
    text-align: center;
  }
  .hideOnSmall {
    display: none;
  }
  .px-5media {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .w-75media {
    width: 100% !important;
  }
  .marginLeft16px {
    margin-left: 0px !important;
  }
  .flexmedia {
    display: block;
  }
  .allplateformtextBox {
    display: none;
  }
  .font60black700 {
    font-size: 30px;
  }
  .blogpageWidth {
    width: 100% !important;
    min-height: 300px !important;
    height: 300px !important;
  }
  .pading20media {
    padding-left: 10%;
    padding-right: 10%;
  }
  .font56black700 {
    font-size: 30px !important;
  }
  .padding0media {
    padding: 0px !important;
  }
  .margin0media {
    margin: 0px !important;
  }
  .font48media {
    font-size: 28px !important;
  }
  .w-50meida {
    width: 100% !important;
  }
  .imgsss {
    width: calc(100% - 0px) !important;
    height: calc(100% - 300px) !important;
  }
  .marBotMedia {
    margin-bottom: 25px;
  }
}

@media (max-width: 767.5px) {
  .font18white400 {
    font-size: 2vw;
  }
  .justiStartCenter {
    display: flex;
    justify-content: center !important;
  }

  .justiEndCenter {
    display: flex;
    justify-content: center !important;
  }
  .flexmedia {
    display: block;
  }
}
.logoImageSize {
  width: 100%;
  height: 40px;
}

.member {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.testinomial-container {
  width: 100%;
  height: 650px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
}
.testinomial-inner {
  width: 350px;
  height: 430px;
  box-shadow: 3px 3px 3px 6px #cdcdcd;
  border-radius: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 40px;
  top: 170px;
}
.testinomila-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.testinomila-title img {
  width: 110px;
  height: 110px;
  border-radius: 40%;
  border: 5px solid #2ec9e3;
  position: relative;
  top: 10px;
  /* left: 20px; */
  object-fit: contain;
}
.test-name-title {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  /* margin-left: 20px; */
  margin-top: 20px;
}

blockquote {
  quotes: "\201C""\201D""\2018""\2019";
  text-align: center;
  padding: 5px;
}
.testinomila-content span:before {
  content: open-quote;
  font-size: 30px;
  font-weight: bold;
}
.testinomila-content span:after {
  content: close-quote;
  font-size: 30px;
  font-weight: bold;
}
.testinomila-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.testinomila-content span {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  line-height: 20px;
}

.tetinimial-head {
  position: absolute;
  top: 30px;
  text-align: center;
  margin-bottom: 40px;
}
