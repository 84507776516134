.at-main-section {
  width: 100%;
  height: 100dvh;
  background: linear-gradient(
      0deg,
      rgba(35, 37, 39, 0.5) 0%,
      rgba(35, 37, 39, 0.5) 100%
    ),
    url("./../../../Images/AccessibilityTravel/accessiblitybg.svg") lightgray
      50% / cover no-repeat;
}

.at-quotes-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.at-quotes {
  display: flex;
  width: 746px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.at-quotes-first {
  width: 32px;
  height: 24px;
}

.at-quotes-second {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 95.833% */
  align-self: stretch;
}

.at-quotes-third {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  align-self: stretch;
  letter-spacing: 1.2px;
}

.at-middle-container {
  display: flex;
  flex-direction: column;
  gap: 112px;
  margin: 130px 40px;
  align-items: center;
  height: auto;
}

.at-middle-container-fst {
  display: flex;
  justify-self: center;
  align-items: center;
  width: 60%;
}

.at-middle-container-fst span {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 127.778% */
}

.at-middle-container-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
}

.at-middle-container-sec img {
  object-fit: contain;
  width: 461.775px;
  height: 187px;
  /* background: lightgray 50% / cover no-repeat; */
}

.at-middle-container-third {
  width: 99%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.at-card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  flex: 1 0 0;
  height: 500px;
}

.at-card-box img {
  border-radius: 16px;
  height: 380px;
  width: 380px;
  object-fit: contain;
  aspect-ratio: 3/4;
  /* align-self: stretch; */
  /* box-shadow: 3px 3px 3px #efefef; */
}

.at-card-box span {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  align-self: stretch;
  margin: 0px 15px;
  width: 90%;
}

.at-last-container {
  width: 100%;
  height: auto;
  padding-top: 100px;
}

.at-last-sec-bg {
  flex-shrink: 0;
  width: 100%;
  height: 700px;
  background: linear-gradient(
      0deg,
      rgba(35, 37, 39, 0) 0%,
      rgba(35, 37, 39, 0) 100%
    ),
    url("./../../../Images/AccessibilityTravel/support-img4.svg")
      rgb(255, 255, 255) 50% / cover no-repeat;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  padding: 80px;
  flex-wrap: wrap;
}

.at-last-crd-box {
  width: 240px;
  height: 280px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(55, 64, 80, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.atlcbs1 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.atlcbs2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 40px 40px 40px;
  line-height: 26px; /* 144.444% */
}

.at-bt-pgd {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 100px;
  padding-bottom: 100px;
  gap: 5px;
}

.at-bt-pgd1 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
}

.at-bt-pgd2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
}
@media (max-width: 767.5px) {
  .at-middle-container-sec {
    gap: 25px;
    justify-content: center;
  }
  .at-middle-container-sec img {
    width: 250px;
    height: 120px;
  }
  /* .at-middle-container {
    height: 1750px;
  } */
  .at-card-box {
    height: 300px;
  }
  .at-card-box img {
    width: 260px;
    height: 260px;
  }
  .at-card-box span {
    font-size: 14px;
    text-align: center;
  }
  .at-middle-container-third {
    gap: 80px;
  }
  .at-middle-container-fst {
    width: 90%;
  }
  .at-middle-container-fst span {
    font-size: 28px;
  }
  .at-last-crd-box {
    width: 180px;
    height: 200px;
    gap: 2px;
  }
  .at-last-crd-box img {
    width: 80px;
    height: 80px;
  }
  .atlcbs1 {
    font-size: 20px;
  }
  .atlcbs2 {
    font-size: 14px;
    padding: 5px 40px 5px 40px;
    line-height: 20px;
  }
  .at-last-sec-bg {
    padding: 10px 60px 10px 60px;
    gap: 30px;
  }
  .pt-80 {
    padding-top: 80px;
  }
}
